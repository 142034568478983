import { cn } from '../../lib/tw';

interface Props {
  className?: string;
}

function TriangleIcon(props: Props) {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="11"
      viewBox="0 0 8 11"
      className={cn('fill-primary-950', className)}
    >
      <path d="M7.38631 5.1128C7.60654 5.28503 7.60654 5.71507 7.38631 5.8873L1.32332 10.6288C1.10288 10.8012 0.827148 10.586 0.827148 10.2416L0.827149 0.758524C0.827149 0.41407 1.10288 0.198873 1.32332 0.371273L7.38631 5.1128Z" />
    </svg>
  );
}

export default TriangleIcon;
