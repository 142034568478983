import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { useRootLoaderDataSafe } from '../../hooks/useRootLoaderData';
import { getIsCsUserByEmail } from '../../lib/cs-lib';
import { getAppName } from '../../lib/env';
import { ROUTES } from '../../lib/links';
import { SyncWithLogo } from '../svg/SyncWithLogo';

const classNames = (...classes: (undefined | string | boolean)[]): string =>
  classes.filter((x) => x && x !== true).join(' ');

interface NavLink {
  name: string;
  href: string;
  more?: boolean;
  noFollow?: boolean;
}

interface NavLinks {
  lookerStudioIntegrations: NavLink[];
  addons: NavLink[];
  googleSheetsIntegrations: NavLink[];
  syncwith: NavLink[];
}

const syncwithNavigation: NavLinks = {
  addons: [
    {
      name: 'GA4 Google Sheets Addon',
      href: '/gs/addons/ga4-google-sheets-addon',
    },
  ],

  lookerStudioIntegrations: [
    {
      name: 'Google Data Studio Facebook Ads',
      href: ROUTES.lookerStudioGoogleDataStudioFacebookAds,
    },
    {
      name: 'Stripe Connector',
      href: ROUTES.lookerStudioStripe,
    },
    {
      name: 'Shopify Connector',
      href: ROUTES.lookerStudioShopify,
    },
  ],
  googleSheetsIntegrations: [
    {
      name: 'Google Analytics to Google Sheets',
      href: ROUTES.googleSheetsGoogleAnalytics,
    },
    {
      name: 'Google Analytics (UA) to Google Sheets',
      href: ROUTES.googleSheetsGoogleAnalyticsUA,
    },
    {
      name: 'MySQL to Google Sheets',
      href: ROUTES.googleSheetsMySQL,
    },
    {
      name: 'Facebook Ads to Google Sheets',
      href: ROUTES.googleSheetsFacebookAds,
    },
  ],
  syncwith: [
    { name: 'About', href: ROUTES.about },
    { name: 'Pricing', href: ROUTES.pricing },
    { name: 'Blog', href: ROUTES.blog },
    { name: 'Careers', href: ROUTES.careers },
    { name: 'Status', href: ROUTES.status },
    { name: 'Support', href: ROUTES.support },
  ],
};

interface Props {
  minimal?: boolean;
}

export const SiteFooter = (props: Props) => {
  const navigation = syncwithNavigation;

  const rootLoaderData = useRootLoaderDataSafe();
  const authenticatedUser = rootLoaderData?.authenticatedUser;

  return (
    <footer
      className="bg-primary-950 py-12 lg:py-16 "
      aria-labelledby="footerHeading"
    >
      {!props.minimal && (
        <div className="max-w-(--breakpoint-xl) mx-auto px-5 sm:px-12 ">
          <div className="mb-16">
            <SyncWithLogo
              className="mt-5 fill-current text-gray-300"
              width={100}
            />
          </div>
          <div className="">
            <div className="md:grid md:grid-cols-4 md:gap-8 xl:col-span-2">
              <div className="mt-12 md:mt-0">
                <h3 className="mt-12 mb-0 md:mb-8 md:mt-0 text-base font-semibold text-ui-100">
                  Google Sheets
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.googleSheetsIntegrations.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        rel={item.noFollow === true ? 'nofollow' : undefined}
                        className={`text-ui-50 text-sm hover:text-ui-50/70 transition-all`}
                      >
                        {item.more && <>&rarr; </>}
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a
                      className="text-secondary-500 hover:text-secondary-500/70 flex text-sm items-center gap-2"
                      href={ROUTES.googleSheetsIntegrations}
                    >
                      All Connectors{' '}
                      <ArrowRightIcon className="text-ui-50 w-4" />
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="mt-12 mb-0 md:mb-8 md:mt-0 font-semibold text-ui-50 text-base ">
                  Looker Studio
                </h3>
                <ul className="mt-4  space-y-4">
                  {navigation.lookerStudioIntegrations.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={`text-sm text-ui-50 hover:text-ui-50/70 transition-all`}
                      >
                        {item.more && <>&rarr; </>}
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a
                      className="text-secondary-500 hover:text-secondary-500/70 flex text-sm items-center gap-2"
                      href={ROUTES.connectors}
                    >
                      All Connectors{' '}
                      <ArrowRightIcon className="text-ui-50 w-4" />
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="mt-12 mb-0 md:mt-0 md:mb-8 text-base font-semibold text-ui-100 ">
                  SyncWith
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.syncwith
                    .filter((item) => {
                      return (
                        !props.minimal ||
                        item.name === 'About' ||
                        item.name === 'hello@syncwith.com'
                      );
                    })
                    .map((item) => (
                      <li key={item.name}>
                        <a
                          target={
                            item.href.startsWith('http') ? '_blank' : undefined
                          }
                          href={item.href}
                          className={`text-sm text-ui-50 hover:text-ui-50/70 transition-all`}
                          rel="nofollow noreferrer"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  {authenticatedUser?.auth.email &&
                    getIsCsUserByEmail(authenticatedUser.auth.email) && (
                      <li key="cs">
                        <a
                          href="https://cs.syncwith.com"
                          className={`text-sm text-ui-50 hover:text-ui-50/70 transition-all`}
                        >
                          Customer support
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={classNames(
          ' px-5 max-w-(--breakpoint-xl) mx-auto sm:px-12 '
        )}
      >
        <div className="flex lg:flex-row flex-col lg:items-center    gap-4 border-t border-ui-50/50  mt-12 pt-12">
          <p className="text-sm text-ui-300 mb-0 ">
            &copy; {new Date().getFullYear()} {getAppName()}
          </p>

          <a
            className="text-ui-300 text-sm mx-0 px-0 text-left"
            href={ROUTES.terms}
            rel="nofollow"
          >
            Terms of Service
          </a>
          <a
            className=" text-ui-300 text-sm mx-0"
            href={ROUTES.privacy}
            rel="nofollow"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};
