/* eslint-disable no-continue */

import { encode } from 'universal-base64url';
import { trackEvent } from './event-tracker.client';
import { GoogleAdsConvClickInstallGoogleSheetsAddon } from './googleAds';
import { trackGoogleAdsConversion } from './googleAds.client';

import { captureException } from './sentry';

export const AddonListingUrl = `https://workspace.google.com/marketplace/app/syncwith_google_analytics_ga4_facebook_a/449644239211?utm_source=syncwith.com`;

export type Event = {
  category: string;
  name: string;
};

export const SheetsAddonClickInstallEvent: Event = {
  category: 'sheets-addon',
  name: 'click-install',
};

export const ClickExternalLink: Event = {
  category: 'external-link',
  name: 'click',
};

export const SpreadsheetClickEvent: Event = {
  category: 'google-spreadsheet',
  name: 'click',
};

export const getTrackedRedirect = (
  url: string,
  event: Event,
  source: string
) => {
  const { category, name } = event;
  const params = new URLSearchParams();

  params.set('category', category);
  params.set('name', name);
  params.set('url', encode(url)); // base64 encode it, to achieve some security through obscurity
  params.set('source', source);

  return `/app/redirect?${params.toString()}`;
};

export const getAddonListingTrackedRedirect = (source: string) => {
  return getTrackedRedirect(
    AddonListingUrl,
    SheetsAddonClickInstallEvent,
    source
  );
};

export const GAAddonListingUrl =
  'https://workspace.google.com/marketplace/app/ga4_google_analytics_addon_by_syncwith/416305139582';

export const FBAddonListingUrl =
  'https://workspace.google.com/marketplace/app/facebook_connector_by_syncwith/525820101725';

export const DBAddonListingUrl =
  'https://workspace.google.com/marketplace/app/postgres_mysql_bigquery_connector_by_syn/501913352944';

export const EcommerceAddonListingUrl =
  'https://workspace.google.com/marketplace/app/shopify_stripe_woocommerce_ecommerce_dat/64519225681';

export const CRMAddonListingUrl =
  'https://workspace.google.com/u/0/marketplace/app/salesforce_hubspot_asana_crm_data/400312729360';

export const AdsAddonListingUrl =
  'https://workspace.google.com/marketplace/app/microsoft_bing_linkedin_pinterest_ads_da/270825264596';

export const SEOAddonListingUrl =
  'https://workspace.google.com/marketplace/app/connector_for_ahrefs_with_google_search/939181641079';

export const DevAddonListingUrl =
  'https://workspace.google.com/marketplace/app/jira_github_project_management_data/729178194104';

export const FinanceAddonListingUrl =
  'https://workspace.google.com/marketplace/app/xero_quickbooks_netsuite_finance_data/346425027449';

export const SocialAddonListingUrl =
  'https://workspace.google.com/u/0/marketplace/app/salesforce_hubspot_asana_crm_data/400312729360'; // todo

export const ProjectManagementddonListingUrl =
  'https://workspace.google.com/marketplace/app/notion_monday_project_management_data/210606334886';

export const TaskManagementAddonListingUrl =
  'https://workspace.google.com/marketplace/app/airtable_trello_zendesk_management_conne/640101451659';

export const GoogleAdsListingUrl =
  'https://workspace.google.com/marketplace/app/google_ads_tiktok_ads_data_by_syncwith/224327267122';

export const ensureUtmParams = (
  url: string,
  params: { [k: string]: string },
  forceParams: { [k: string]: string } = {}
): string => {
  const parsed = new URL(url);

  for (const [k, v] of Object.entries(params)) {
    if (!v) continue;

    if (parsed.searchParams.get(k)) continue;

    parsed.searchParams.append(k, v);
  }

  for (const [k, v] of Object.entries(forceParams)) {
    parsed.searchParams.set(k, v);
  }

  return parsed.toString();
};

export const applyUtmParams = (url: string, source: string) => {
  return ensureUtmParams(url, {
    utm_campaign: source,
    utm_source: 'syncwith.com',
    utm_medium: 'referral',
  });
};

export const handleClickInstallAddon = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  dataSource?: string
) => {
  e.preventDefault();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const unsafeTarget = e.target as any;

  const source =
    dataSource ||
    (unsafeTarget.getAttribute && unsafeTarget.getAttribute('data-source')) ||
    'unknown';
  const href = unsafeTarget?.href || getAddonListingTrackedRedirect(source);

  // be very defensive
  try {
    trackEvent(SheetsAddonClickInstallEvent, {
      label: source, // for GA
      source,
      href,
      pathname: window.location.pathname,
    });
    // Track to Google Ads
    trackGoogleAdsConversion(GoogleAdsConvClickInstallGoogleSheetsAddon);
  } catch (err) {
    captureException(err);
  }

  window.open(href, '_blank');

  // https://stackoverflow.com/questions/7347786/html-anchor-tag-with-javascript-onclick-event
  return false;
};

export const ROUTES = {
  googleSheetsIntegrations: '/gs/integrations',
  lookerStudioIntegrations: '/ls/connectors',
  googleSheets: '/gs',
  lookerStudio: '/ls',
  pricing: '/pricing',
  login: '/account/login',
  terms: '/terms',
  about: '/about',
  blog: '/blog',
  careers: '/careers',
  account: '/account',
  support: 'https://support.syncwith.com',
  status: 'https://status.syncwith.com',
  privacy: '/privacy',
  connectors: '/ls/connectors',
  lookerStudioShopify: `/ls/connectors/shopify-looker-studio-connector`,
  lookerStudioStripe: `/ls/connectors/stripe-looker-studio-connector`,
  lookerStudioWooCommerce: `/ls/connectors/woocommerce-looker-studio-connector`,
  lookerStudioPostgres: '/ls/connectors/google-data-studio-postgres',
  lookerStudioGoogleDataStudioFacebookAds: `/ls/connectors/google-data-studio-facebook-ads`,
  lookerStudioFacebookAds: '/ls/connectors/google-data-studio-facebook-ads',

  googleSheetsGoogleAnalytics: `/gs/integrations/ga4-to-google-sheets`,
  googleSheetsGoogleAnalyticsUA: `/gs/integrations/google-analytics-to-google-sheets`,
  googleSheetsGoogleAds: `/gs/integrations/google-ads-to-google-sheets`,
  googleSheetsMySQL: `/gs/integrations/mysql-to-google-sheets`,
  googleSheetsFacebookAds: `/gs/integrations/facebook-ads-to-google-sheets`,
  googleSheetsShopify: `/gs/integrations/shopify-to-google-sheets`,
  googleSheetsSearchConsole: `/gs/integrations/google-search-console-to-google-sheets`,
  googleSheetsPostgres: `/gs/integrations/postgres-to-google-sheets`,
} as const;
