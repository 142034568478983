import { NestedError } from './nested-error';
import { captureException } from './sentry';

export const trackGoogleAdsConversion = (conversionId: string) => {
  try {
    gtag('event', 'conversion', {
      send_to: conversionId,
    });
  } catch (err) {
    const nested = new NestedError(
      `Failed to track conversion to Google Ads: ${
        err instanceof Error ? err.message : JSON.stringify(err)
      }`,
      err
    );
    console.error(nested);
    captureException(nested, { conversionId });
  }
};
