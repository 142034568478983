import { useMatches } from '@remix-run/react';
import { RootLoaderData } from '../root';

// This has to match whats in Root.tsx
export const useRootLoaderData = (): RootLoaderData => {
  const matches = useMatches();
  if (matches.length > 0) {
    const routeData = matches[0].data;
    if (!routeData) {
      throw new Error(`No root loader data found`);
    }
    return routeData as RootLoaderData;
  }
  throw new Error(`No root loader data found`);
};

// I'm not sure why this is needed, but I'm seeing a lot of errors in Sentry, and our sentry integration
// is rather lacking, so I can't figure out whats going on easily
// Going to punt it for now, and wait for sentry-remix to be solid, then hopefully we can see whats going on
export const useRootLoaderDataSafe = (): RootLoaderData | undefined => {
  try {
    return useRootLoaderData();
  } catch (err) {
    return undefined;
  }
};
