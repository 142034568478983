import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cn } from '../../lib/tw';

const buttonVariants = cva(
  'inline-flex items-center cursor-pointer text-sm justify-center rounded-full font-semibold text-ui-50 transition-all',
  {
    variants: {
      variant: {
        defaultWhiteBg:
          'bg-primary-500 hover:bg-primary-950 hover:text-ui-50 active:bg-primary-500 active:text-ui-50',
        default:
          'bg-primary-500 hover:bg-white hover:text-primary-500 active:bg-primary-500 active:text-ui-50',
        secondary:
          'border-primary-500 text-ui-50 border-2 hover:border-ui-50 focus:border-primary-500',
        underline: 'text-ui-950 underline hover:no-underline text-base',
      },
      size: {
        default: 'h-10 px-8',
        sm: 'h-9 px-3 ',
        lg: 'h-12 px-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const ButtonNew = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
ButtonNew.displayName = 'Button';

export { ButtonNew, buttonVariants };
