import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useRootLoaderDataSafe } from '../../../hooks/useRootLoaderData';
import {
  ROUTES,
  getAddonListingTrackedRedirect,
  handleClickInstallAddon,
} from '../../../lib/links';
import { SyncWithLogo } from '../../svg/SyncWithLogo';
import { ButtonNew } from '../../ui/ButtonNew';
import TriangleIcon from '../../ui/TriangleIcon';

export default function NavigationPhone() {
  const [isOpen, setIsOpen] = useState(false);
  const rootData = useRootLoaderDataSafe();

  return (
    <div className="flex items-center justify-center lg:hidden">
      <button
        className="cursor-pointer"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <Bars3Icon className="text-white w-6" />
      </button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="fixed  h-screen w-screen max-w-(--breakpoint-xl) mx-auto px-3  inset-0 bg-primary-800 ">
          <DialogPanel className="h-screen outline-hidden	  pt-6 px-6 w-screen bg-primary-800 fixed top-0 left-0 inset-1 z-50">
            <div
              aria-label="Phone Navigation Trigger"
              className="flex justify-between items-center"
            >
              <SyncWithLogo width={120} className="mr-1" />
              <button
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <XMarkIcon className="text-white w-6" />
              </button>
            </div>
            <div className="animate-fade-in">
              <div className="">
                <a
                  className="py-5 flex items-center justify-between mt-6  border-y  border-navbar-borderPhone text-ui-50 text-lg font-semibold"
                  href={ROUTES.googleSheets}
                >
                  Google Sheets
                  <TriangleIcon className="fill-white" />
                </a>
                <a
                  className="py-5 flex items-center justify-between text-ui-50 text-lg font-semibold border-b border-navbar-borderPhone"
                  href={ROUTES.lookerStudio}
                >
                  Looker Studio
                  <TriangleIcon className="fill-white" />
                </a>
                <a
                  className="py-5 flex items-center justify-between text-ui-50 text-lg font-semibold border-b border-navbar-borderPhone"
                  href={ROUTES.pricing}
                >
                  Pricing
                  <TriangleIcon className="fill-white" />
                </a>
              </div>
              <div className="mt-8 flex flex-col gap-4">
                <ButtonNew asChild size="lg">
                  <a
                    onClick={(e) => handleClickInstallAddon(e, 'sitenav')}
                    href={getAddonListingTrackedRedirect('sitenav')}
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    Try It for Free
                  </a>
                </ButtonNew>
                {rootData?.authenticatedUser ? (
                  <ButtonNew size="lg" variant="secondary" asChild>
                    <a href={ROUTES.account}>My Account</a>
                  </ButtonNew>
                ) : (
                  <ButtonNew size="lg" variant="secondary" asChild>
                    <a href={ROUTES.login}>Sign in</a>
                  </ButtonNew>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}
