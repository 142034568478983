import { useRootLoaderDataSafe } from '../../../hooks/useRootLoaderData';
import {
  ROUTES,
  getAddonListingTrackedRedirect,
  handleClickInstallAddon,
} from '../../../lib/links';
import { ButtonNew } from '../../ui/ButtonNew';

interface Props {
  isLookerStudio: boolean;
}

function NavigationDesktop(props: Props) {
  const { isLookerStudio } = props;
  const rootData = useRootLoaderDataSafe();

  return (
    <>
      <ul className="hidden lg:flex gap-8 items-center text-md font-semibold text-ui-50">
        <li className="">
          <a
            className="block text-ui-50 text-sm hover:text-ui-50/70 transition-all font-semibold"
            href={ROUTES.googleSheets}
          >
            Google Sheets
          </a>
        </li>
        <li className="">
          <a
            className="block text-ui-50 text-sm hover:text-ui-50/70 transition-all  font-semibold"
            href={ROUTES.lookerStudio}
          >
            Looker Studio
          </a>
        </li>
        <li className="">
          <a
            className=" block text-ui-50 text-sm hover:text-ui-50/70 transition-all font-semibold"
            href={ROUTES.pricing}
          >
            Pricing
          </a>
        </li>
      </ul>
      <div className=" items-center gap-4 hidden lg:flex">
        <div className="text-ui-50  text-center w-[85px] hover:text-ui-50/70 transition-all text-sm">
          {rootData?.authenticatedUser ? (
            <a className="h-full py-2 block" href={ROUTES.account}>
              My Account
            </a>
          ) : (
            <a className="h-full py-2 block" href={ROUTES.login}>
              Sign in
            </a>
          )}
        </div>
        <div>
          {isLookerStudio ? (
            <ButtonNew asChild>
              <a href="/ls/connectors">Try It for Free</a>
            </ButtonNew>
          ) : (
            <ButtonNew asChild>
              <a
                onClick={(e) => handleClickInstallAddon(e, 'sitenav')}
                href={getAddonListingTrackedRedirect('sitenav')}
                target="_blank"
                rel="noopener nofollow"
              >
                Try It for Free
              </a>
            </ButtonNew>
          )}
        </div>
      </div>
    </>
  );
}

export default NavigationDesktop;
