import { useMatches } from '@remix-run/react';
import { cn } from '../../../lib/tw';
import { SyncWithLogo } from '../../svg/SyncWithLogo';
import NavigationDesktop from './NavigationDesktop';
import NavigationPhone from './NavigationPhone';

interface Props {
  minimal?: boolean;
}

export const SiteNav = ({ minimal }: Props) => {
  const matches = useMatches();
  const route = matches[matches.length - 1];
  const isLookerStudio = route.pathname.startsWith('/ls');

  return (
    <div className="bg-primary-800 py-6 lg:py-8 px-6 ">
      <div className="max-w-(--breakpoint-xl) mx-auto">
        <div
          className={cn(`flex items-center w-full justify-between`, {
            'justify-center': minimal,
          })}
        >
          <a
            aria-label="SyncWith logo link to home page"
            className={cn('lg:w-[12.438rem]', {
              'lg:w-auto': minimal,
            })}
            href="/"
          >
            <SyncWithLogo width={120} className="mr-1" />
          </a>
          {!minimal && (
            <>
              <NavigationPhone />
              <NavigationDesktop isLookerStudio={isLookerStudio} />
            </>
          )}

          {/* {!props.minimal && (
                <div
                  className={`flex-1 flex items-center justify-end px-2 lg:ml-6 lg:justify-end my-3 md:my-0`}
                >
                  <div></div>
                  <div className="ml-2">
                    <div className="my-0 md:my-3 inline-block rounded-md sm:mt-0 sm:shrink-0">
                      {isLookerStudio ? (
                        <a
                          href="/ls/connectors"
                          className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:px-6 whitespace-nowrap"
                        >
                          Get started
                        </a>
                      ) : (
                        <a
                          href={getAddonListingTrackedRedirect('sitenav')}
                          target="_blank"
                          onClick={(e) => handleClickInstallAddon(e, 'sitenav')}
                          className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:px-6 whitespace-nowrap"
                          rel="noopener nofollow"
                        >
                          Get started
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )} */}
        </div>
      </div>
    </div>
  );
};
